export async function setup() {
  const videoData = [];

  videoData.beatTheHacker2Phy = [];
  videoData.beatTheHacker2Phy.English = [];

  videoData.beatTheHacker2Phy.English.introvid = require("../assets/video/beatTheHacker2Phy/English/introvid.mp4");
  videoData.beatTheHacker2Phy.English.introvidTime = 20000; // 28000;
  videoData.beatTheHacker2Phy.English.introvidSubs = require("../assets/video/beatTheHacker2Phy/English/introvid.vtt");

  videoData.beatTheHacker2Phy.English.stage3 = require("../assets/video/beatTheHacker2Phy/English/stage3.mp4");
  videoData.beatTheHacker2Phy.English.stage3Time = 134000; // 28000;
  videoData.beatTheHacker2Phy.English.stage3Subs = require("../assets/video/beatTheHacker2Phy/English/stage3.vtt");

  videoData.beatTheHacker2Phy.English.stage6 = require("../assets/video/beatTheHacker2Phy/English/stage6.mp4");
  videoData.beatTheHacker2Phy.English.stage6Time = 39000; // 28000;
  videoData.beatTheHacker2Phy.English.stage6Subs = require("../assets/video/beatTheHacker2Phy/English/stage6.vtt");

  videoData.beatTheHacker2Phy.English.stage9 = require("../assets/video/beatTheHacker2Phy/English/stage9.mp4");
  videoData.beatTheHacker2Phy.English.stage9Time = 21000; // 28000;
  videoData.beatTheHacker2Phy.English.stage9Subs = require("../assets/video/beatTheHacker2Phy/English/stage9.vtt");

  videoData.beatTheHacker2Phy.English.stage11 = require("../assets/video/beatTheHacker2Phy/English/stage11.mp4");
  videoData.beatTheHacker2Phy.English.stage11Time = 22000; // 28000;
  videoData.beatTheHacker2Phy.English.stage11Subs = require("../assets/video/beatTheHacker2Phy/English/stage11.vtt");

  videoData.beatTheHacker2Phy.English.stage13 = require("../assets/video/beatTheHacker2Phy/English/stage13.mp4");
  videoData.beatTheHacker2Phy.English.stage13Time = 30000; // 28000;
  videoData.beatTheHacker2Phy.English.stage13Subs = require("../assets/video/beatTheHacker2Phy/English/stage13.vtt");

  videoData.beatTheHacker2Phy.English.stage15 = require("../assets/video/beatTheHacker2Phy/English/stage15.mp4");
  videoData.beatTheHacker2Phy.English.stage15Time = 22000; // 28000;
  videoData.beatTheHacker2Phy.English.stage15Subs = require("../assets/video/beatTheHacker2Phy/English/stage15.vtt");

  videoData.beatTheHacker2Phy.English.stage17 = require("../assets/video/beatTheHacker2Phy/English/stage17.mp4");
  videoData.beatTheHacker2Phy.English.stage17Time = 17000; // 28000;
  videoData.beatTheHacker2Phy.English.stage17Subs = require("../assets/video/beatTheHacker2Phy/English/stage17.vtt");

  videoData.beatTheHacker2Phy.English.stage19 = require("../assets/video/beatTheHacker2Phy/English/stage19.mp4");
  videoData.beatTheHacker2Phy.English.stage19Time = 17000; // 28000;
  videoData.beatTheHacker2Phy.English.stage19Subs = require("../assets/video/beatTheHacker2Phy/English/stage19.vtt");

  videoData.beatTheHacker2Phy.English.stage21 = require("../assets/video/beatTheHacker2Phy/English/stage21.mp4");
  videoData.beatTheHacker2Phy.English.stage21Time = 19000; // 28000;
  videoData.beatTheHacker2Phy.English.stage21Subs = require("../assets/video/beatTheHacker2Phy/English/stage21.vtt");

  videoData.beatTheHacker2Phy.English.stage23 = require("../assets/video/beatTheHacker2Phy/English/stage23.mp4");
  videoData.beatTheHacker2Phy.English.stage23Time = 14000; // 28000;
  videoData.beatTheHacker2Phy.English.stage23Subs = require("../assets/video/beatTheHacker2Phy/English/stage23.vtt");

  videoData.beatTheHacker2Phy.English.stage26 = require("../assets/video/beatTheHacker2Phy/English/stage26.mp4");
  videoData.beatTheHacker2Phy.English.stage26Time = 11000; // 28000;
  videoData.beatTheHacker2Phy.English.stage26Subs = require("../assets/video/beatTheHacker2Phy/English/stage26.vtt");

  videoData.beatTheHacker2Phy.English.stage28 = require("../assets/video/beatTheHacker2Phy/English/stage28.mp4");
  videoData.beatTheHacker2Phy.English.stage28Time = 13000; // 28000;
  videoData.beatTheHacker2Phy.English.stage28Subs = require("../assets/video/beatTheHacker2Phy/English/stage28.vtt");

  videoData.beatTheHacker2Phy.English.stage31 = require("../assets/video/beatTheHacker2Phy/English/stage31.mp4");
  videoData.beatTheHacker2Phy.English.stage31Time = 54000; // 28000;
  videoData.beatTheHacker2Phy.English.stage31Subs = require("../assets/video/beatTheHacker2Phy/English/stage31.vtt");

  videoData.beatTheHacker2Phy.English.deepfake1 = require("../assets/video/beatTheHacker2Phy/English/deepfake1.mp4");
  videoData.beatTheHacker2Phy.English.deepfake1Time = 32000; // 28000;
  videoData.beatTheHacker2Phy.English.deepfake1Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake1.vtt");

  videoData.beatTheHacker2Phy.English.deepfake3 = require("../assets/video/beatTheHacker2Phy/English/deepfake2.mp4");
  videoData.beatTheHacker2Phy.English.deepfake3Time = 49000; // 28000;
  videoData.beatTheHacker2Phy.English.deepfake3Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake2.vtt");

  videoData.beatTheHacker2Phy.English.stage121 = require("../assets/video/beatTheHacker2Phy/English/stage121.mp4");
  videoData.beatTheHacker2Phy.English.stage121Time = 10000; // 28000;
  videoData.beatTheHacker2Phy.English.stage121Subs = require("../assets/video/beatTheHacker2Phy/English/stage121.vtt");

  // videoData.beatTheHacker2Phy.English.stage32 = require("../assets/video/beatTheHacker2Phy/English/stage32.mp4");
  // videoData.beatTheHacker2Phy.English.stage32Time = 19000; // 28000;
  // videoData.beatTheHacker2Phy.English.stage32Subs = require("../assets/video/beatTheHacker2Phy/English/stage32.vtt");

  videoData.beatTheHacker2env = [];
  videoData.beatTheHacker2env.English = [];

  videoData.beatTheHacker2env.English.stage3 = require("../assets/video/beatTheHacker2env/English/stage1.mp4");
  videoData.beatTheHacker2env.English.stage3Time = 72000; // 28000;
  videoData.beatTheHacker2env.English.stage3Subs = require("../assets/video/beatTheHacker2env/English/stage1.vtt");

  videoData.beatTheHacker2env.English.stage6 = require("../assets/video/beatTheHacker2env/English/stage5.mp4");
  videoData.beatTheHacker2env.English.stage6Time = 39000; // 28000;
  videoData.beatTheHacker2env.English.stage6Subs = require("../assets/video/beatTheHacker2env/English/stage5.vtt");

  videoData.beatTheHacker2env.English.stage9 = require("../assets/video/beatTheHacker2env/English/stage8.mp4");
  videoData.beatTheHacker2env.English.stage9Time = 12000; // 28000;
  videoData.beatTheHacker2env.English.stage9Subs = require("../assets/video/beatTheHacker2env/English/stage8.vtt");

  videoData.beatTheHacker2env.English.stage11 = require("../assets/video/beatTheHacker2env/English/stage10.mp4");
  videoData.beatTheHacker2env.English.stage11Time = 66000; // 28000;
  videoData.beatTheHacker2env.English.stage11Subs = require("../assets/video/beatTheHacker2env/English/stage10.vtt");

  videoData.beatTheHacker2env.English.stage13 = require("../assets/video/beatTheHacker2env/English/stage16.mp4");
  videoData.beatTheHacker2env.English.stage13Time = 52000; // 28000;
  videoData.beatTheHacker2env.English.stage13Subs = require("../assets/video/beatTheHacker2env/English/stage16.vtt");

  videoData.beatTheHacker2env.English.stage15 = require("../assets/video/beatTheHacker2env/English/stage19.mp4");
  videoData.beatTheHacker2env.English.stage15Time = 22000; // 28000;
  videoData.beatTheHacker2env.English.stage15Subs = require("../assets/video/beatTheHacker2env/English/stage19.vtt");

  videoData.beatTheHacker2env.English.stage17 = require("../assets/video/beatTheHacker2env/English/stage22.mp4");
  videoData.beatTheHacker2env.English.stage17Time = 28000; // 28000;
  videoData.beatTheHacker2env.English.stage17Subs = require("../assets/video/beatTheHacker2env/English/stage22.vtt");

  videoData.beatTheHacker2env.English.stage19 = require("../assets/video/beatTheHacker2env/English/stage24.mp4");
  videoData.beatTheHacker2env.English.stage19Time = 30000; // 28000;
  videoData.beatTheHacker2env.English.stage19Subs = require("../assets/video/beatTheHacker2env/English/stage24.vtt");

  videoData.beatTheHacker2env.English.stage21 = require("../assets/video/beatTheHacker2env/English/stage27.mp4");
  videoData.beatTheHacker2env.English.stage21Time = 19000; // 28000;
  videoData.beatTheHacker2env.English.stage21Subs = require("../assets/video/beatTheHacker2env/English/stage27.vtt");

  videoData.beatTheHacker2env.English.stage23 = require("../assets/video/beatTheHacker2env/English/stage31.mp4");
  videoData.beatTheHacker2env.English.stage23Time = 11000; // 28000;
  videoData.beatTheHacker2env.English.stage23Subs = require("../assets/video/beatTheHacker2env/English/stage31.vtt");

  videoData.beatTheHacker2env.English.stage26 = require("../assets/video/beatTheHacker2env/English/stage33.mp4");
  videoData.beatTheHacker2env.English.stage26Time = 11000; // 28000;
  videoData.beatTheHacker2env.English.stage26Subs = require("../assets/video/beatTheHacker2env/English/stage33.vtt");

  videoData.beatTheHacker2env.English.stage28 = require("../assets/video/beatTheHacker2env/English/stage40.mp4");
  videoData.beatTheHacker2env.English.stage28Time = 44000; // 28000;
  videoData.beatTheHacker2env.English.stage28Subs = require("../assets/video/beatTheHacker2env/English/stage40.vtt");

  videoData.beatTheHacker2env.English.stage31 = require("../assets/video/beatTheHacker2env/English/stage44.mp4");
  videoData.beatTheHacker2env.English.stage31Time = 19000; // 28000;
  videoData.beatTheHacker2env.English.stage31Subs = require("../assets/video/beatTheHacker2env/English/stage44.vtt");

  videoData.beatTheHacker2env.English.stage32 = require("../assets/video/beatTheHacker2env/English/stage44.mp4");
  videoData.beatTheHacker2env.English.stage32Time = 19000; // 28000;
  videoData.beatTheHacker2env.English.stage32Subs = require("../assets/video/beatTheHacker2env/English/stage44.vtt");

  videoData.beatTheHacker2Phy.English.deepfake1 = require("../assets/video/beatTheHacker2Phy/English/deepfake1.mp4");
  videoData.beatTheHacker2Phy.English.deepfake1Time = 32000; // 28000;
  videoData.beatTheHacker2Phy.English.deepfake1Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake1.vtt");

  videoData.beatTheHacker2Phy.English.deepfake2 = require("../assets/video/beatTheHacker2Phy/English/deepfake2.mp4");
  videoData.beatTheHacker2Phy.English.deepfake2Time = 49000; // 28000;
  videoData.beatTheHacker2Phy.English.deepfake2Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake2.vtt");

  videoData.openBanking = [];
  videoData.openBanking.English = [];

  videoData.openBanking.English.introvid = require("../assets/video/openBanking/English/introvid.mp4");
  videoData.openBanking.English.introvidTime = 20000; // 28000;
  videoData.openBanking.English.introvidSubs = require("../assets/video/openBanking/English/introvid.vtt");

  videoData.openBanking.English.stage3 = require("../assets/video/openBanking/English/stage3.mp4");
  videoData.openBanking.English.stage3Time = 215000; // 28000;
  videoData.openBanking.English.stage3Subs = require("../assets/video/openBanking/English/stage3.vtt");

  videoData.openBanking.English.stage6 = require("../assets/video/openBanking/English/stage6.mp4");
  videoData.openBanking.English.stage6Time = 53000; // 28000;
  videoData.openBanking.English.stage6Subs = require("../assets/video/openBanking/English/stage6.vtt");

  videoData.openBanking.English.stage9 = require("../assets/video/openBanking/English/stage9.mp4");
  videoData.openBanking.English.stage9Time = 48000; // 28000;
  videoData.openBanking.English.stage9Subs = require("../assets/video/openBanking/English/stage9.vtt");

  videoData.openBanking.English.stage11 = require("../assets/video/openBanking/English/stage11.mp4");
  videoData.openBanking.English.stage11Time = 24000; // 28000;
  videoData.openBanking.English.stage11Subs = require("../assets/video/openBanking/English/stage11.vtt");

  videoData.openBanking.English.stage14 = require("../assets/video/openBanking/English/stage14.mp4");
  videoData.openBanking.English.stage14Time = 33000; // 28000;
  videoData.openBanking.English.stage14Subs = require("../assets/video/openBanking/English/stage14.vtt");

  videoData.openBanking.English.stage16 = require("../assets/video/openBanking/English/stage16.mp4");
  videoData.openBanking.English.stage16Time = 17000; // 28000;
  videoData.openBanking.English.stage16Subs = require("../assets/video/openBanking/English/stage16.vtt");

  videoData.openBanking.English.stage18 = require("../assets/video/openBanking/English/stage18.mp4");
  videoData.openBanking.English.stage18Time = 28000; // 28000;
  videoData.openBanking.English.stage18Subs = require("../assets/video/openBanking/English/stage18.vtt");

  videoData.openBanking.English.stage19 = require("../assets/video/openBanking/English/stage19.mp4");
  videoData.openBanking.English.stage19Time = 17000; // 28000;
  videoData.openBanking.English.stage19Subs = require("../assets/video/openBanking/English/stage19.vtt");

  videoData.openBanking.English.stage23 = require("../assets/video/openBanking/English/stage23.mp4");
  videoData.openBanking.English.stage23Time = 36000; // 28000;
  videoData.openBanking.English.stage23Subs = require("../assets/video/openBanking/English/stage23.vtt");

  videoData.openBanking.English.stage25 = require("../assets/video/openBanking/English/stage25.mp4");
  videoData.openBanking.English.stage25Time = 48000; // 28000;
  videoData.openBanking.English.stage25Subs = require("../assets/video/openBanking/English/stage25.vtt");

  videoData.openBanking.English.stage27 = require("../assets/video/openBanking/English/stage27.mp4");
  videoData.openBanking.English.stage27Time = 30000; // 28000;
  videoData.openBanking.English.stage27Subs = require("../assets/video/openBanking/English/stage27.vtt");

  videoData.openBanking.English.stage30 = require("../assets/video/openBanking/English/stage30.mp4");
  videoData.openBanking.English.stage30Time = 94000; // 28000;
  videoData.openBanking.English.stage30Subs = require("../assets/video/openBanking/English/stage30.vtt");

  videoData.openBanking.English.stage121 = require("../assets/video/openBanking/English/stage121.mp4");
  videoData.openBanking.English.stage121Time = 10000; // 28000;
  videoData.openBanking.English.stage121Subs = require("../assets/video/openBanking/English/stage121.vtt");

  videoData.openBanking.English.stage291ob = require("../assets/video/openBanking/English/stage291ob.mp4");
  videoData.openBanking.English.stage291obTime = 10000; // 28000;
  videoData.openBanking.English.stage291obSubs = require("../assets/video/openBanking/English/stage291ob.vtt");

  videoData.lidl = [];
  videoData.lidl.English = [];

  videoData.lidl.English.introvid = require("../assets/video/lidl/English/introvid.mp4");
  videoData.lidl.English.introvidTime = 20000; // 28000;
  videoData.lidl.English.introvidSubs = require("../assets/video/lidl/English/introvid.vtt");

  videoData.lidl.English.stage3 = require("../assets/video/lidl/English/stage3.mp4");
  videoData.lidl.English.stage3Time = 147000; // 28000;
  videoData.lidl.English.stage3Subs = require("../assets/video/lidl/English/stage3.vtt");

  videoData.lidl.English.stage6 = require("../assets/video/lidl/English/stage6.mp4");
  videoData.lidl.English.stage6Time = 54000; // 28000;
  videoData.lidl.English.stage6Subs = require("../assets/video/lidl/English/stage6.vtt");

  videoData.lidl.English.stage9 = require("../assets/video/lidl/English/stage9.mp4");
  videoData.lidl.English.stage9Time = 41000; // 28000;
  videoData.lidl.English.stage9Subs = require("../assets/video/lidl/English/stage9.vtt");

  videoData.lidl.English.stage11 = require("../assets/video/lidl/English/stage11.mp4");
  videoData.lidl.English.stage11Time = 28000; // 28000;
  videoData.lidl.English.stage11Subs = require("../assets/video/lidl/English/stage11.vtt");

  videoData.lidl.English.stage13 = require("../assets/video/lidl/English/stage13.mp4");
  videoData.lidl.English.stage13Time = 38000; // 28000;
  videoData.lidl.English.stage13Subs = require("../assets/video/lidl/English/stage13.vtt");

  videoData.lidl.English.stage15 = require("../assets/video/lidl/English/stage15.mp4");
  videoData.lidl.English.stage15Time = 44000; // 28000;
  videoData.lidl.English.stage15Subs = require("../assets/video/lidl/English/stage15.vtt");

  videoData.lidl.English.stage17 = require("../assets/video/lidl/English/stage17.mp4");
  videoData.lidl.English.stage17Time = 17000; // 28000;
  videoData.lidl.English.stage17Subs = require("../assets/video/lidl/English/stage17.vtt");

  videoData.lidl.English.stage19 = require("../assets/video/lidl/English/stage19.mp4");
  videoData.lidl.English.stage19Time = 17000; // 28000;
  videoData.lidl.English.stage19Subs = require("../assets/video/lidl/English/stage19.vtt");

  videoData.lidl.English.stage21 = require("../assets/video/lidl/English/stage21.mp4");
  videoData.lidl.English.stage21Time = 30000; // 28000;
  videoData.lidl.English.stage21Subs = require("../assets/video/lidl/English/stage21.vtt");

  videoData.lidl.English.stage23 = require("../assets/video/lidl/English/stage23.mp4");
  videoData.lidl.English.stage23Time = 14000; // 28000;
  videoData.lidl.English.stage23Subs = require("../assets/video/lidl/English/stage23.vtt");

  videoData.lidl.English.stage26 = require("../assets/video/lidl/English/stage26.mp4");
  videoData.lidl.English.stage26Time = 27000; // 28000;
  videoData.lidl.English.stage26Subs = require("../assets/video/lidl/English/stage26.vtt");

  videoData.lidl.English.stage28 = require("../assets/video/lidl/English/stage28.mp4");
  videoData.lidl.English.stage28Time = 27000; // 28000;
  videoData.lidl.English.stage28Subs = require("../assets/video/lidl/English/stage28.vtt");

  videoData.lidl.English.stage31 = require("../assets/video/lidl/English/stage31.mp4");
  videoData.lidl.English.stage31Time = 90000; // 28000;
  videoData.lidl.English.stage31Subs = require("../assets/video/lidl/English/stage31.vtt");

  videoData.lidl.English.deepfake1 = require("../assets/video/lidl/English/deepfake1.mp4");
  videoData.lidl.English.deepfake1Time = 37000; // 28000;
  videoData.lidl.English.deepfake1Subs = require("../assets/video/lidl/English/deepfake1.vtt");

  videoData.lidl.English.deepfake3 = require("../assets/video/lidl/English/deepfake2.mp4");
  videoData.lidl.English.deepfake3Time = 72000; // 28000;
  videoData.lidl.English.deepfake3Subs = require("../assets/video/lidl/English/deepfake2.vtt");

  videoData.lidl.English.stage121 = require("../assets/video/lidl/English/stage121.mp4");
  videoData.lidl.English.stage121Time = 10000; // 28000;
  videoData.lidl.English.stage121Subs = require("../assets/video/lidl/English/stage121.vtt");

  videoData.lidl.English.lidlsizzle = require("../assets/video/lidl/English/BTHLidlTrailer.mp4");
  videoData.lidl.English.lidlsizzleTime = 33000; // 28000;
  videoData.lidl.English.lidlsizzleSubs = require("../assets/video/lidl/English/BTHLidlTrailer.vtt");

  videoData.schwarz = [];
  videoData.schwarz.English = [];

  videoData.schwarz.English.introvid = require("../assets/video/beatTheHacker2Phy/English/introvid.mp4");
  videoData.schwarz.English.introvidTime = 20000; // 28000;
  videoData.schwarz.English.introvidSubs = require("../assets/video/beatTheHacker2Phy/English/introvid.vtt");

  videoData.schwarz.English.stage3 = require("../assets/video/schwarz/English/stage3.mp4");
  videoData.schwarz.English.stage3Time = 134000; // 28000;
  videoData.schwarz.English.stage3Subs = require("../assets/video/schwarz/English/stage3.vtt");

  videoData.schwarz.English.stage6 = require("../assets/video/beatTheHacker2Phy/English/stage6.mp4");
  videoData.schwarz.English.stage6Time = 39000; // 28000;
  videoData.schwarz.English.stage6Subs = require("../assets/video/beatTheHacker2Phy/English/stage6.vtt");

  videoData.schwarz.English.stage9 = require("../assets/video/beatTheHacker2Phy/English/stage9.mp4");
  videoData.schwarz.English.stage9Time = 21000; // 28000;
  videoData.schwarz.English.stage9Subs = require("../assets/video/beatTheHacker2Phy/English/stage9.vtt");

  videoData.schwarz.English.stage11 = require("../assets/video/beatTheHacker2Phy/English/stage11.mp4");
  videoData.schwarz.English.stage11Time = 22000; // 28000;
  videoData.schwarz.English.stage11Subs = require("../assets/video/beatTheHacker2Phy/English/stage11.vtt");

  videoData.schwarz.English.stage13 = require("../assets/video/beatTheHacker2Phy/English/stage13.mp4");
  videoData.schwarz.English.stage13Time = 30000; // 28000;
  videoData.schwarz.English.stage13Subs = require("../assets/video/beatTheHacker2Phy/English/stage13.vtt");

  videoData.schwarz.English.stage15 = require("../assets/video/beatTheHacker2Phy/English/stage15.mp4");
  videoData.schwarz.English.stage15Time = 22000; // 28000;
  videoData.schwarz.English.stage15Subs = require("../assets/video/beatTheHacker2Phy/English/stage15.vtt");

  videoData.schwarz.English.stage17 = require("../assets/video/beatTheHacker2Phy/English/stage17.mp4");
  videoData.schwarz.English.stage17Time = 17000; // 28000;
  videoData.schwarz.English.stage17Subs = require("../assets/video/beatTheHacker2Phy/English/stage17.vtt");

  videoData.schwarz.English.stage19 = require("../assets/video/beatTheHacker2Phy/English/stage19.mp4");
  videoData.schwarz.English.stage19Time = 17000; // 28000;
  videoData.schwarz.English.stage19Subs = require("../assets/video/beatTheHacker2Phy/English/stage19.vtt");

  videoData.schwarz.English.stage21 = require("../assets/video/beatTheHacker2Phy/English/stage21.mp4");
  videoData.schwarz.English.stage21Time = 19000; // 28000;
  videoData.schwarz.English.stage21Subs = require("../assets/video/beatTheHacker2Phy/English/stage21.vtt");

  videoData.schwarz.English.stage23 = require("../assets/video/beatTheHacker2Phy/English/stage23.mp4");
  videoData.schwarz.English.stage23Time = 14000; // 28000;
  videoData.schwarz.English.stage23Subs = require("../assets/video/beatTheHacker2Phy/English/stage23.vtt");

  videoData.schwarz.English.stage26 = require("../assets/video/beatTheHacker2Phy/English/stage26.mp4");
  videoData.schwarz.English.stage26Time = 11000; // 28000;
  videoData.schwarz.English.stage26Subs = require("../assets/video/beatTheHacker2Phy/English/stage26.vtt");

  videoData.schwarz.English.stage28 = require("../assets/video/beatTheHacker2Phy/English/stage28.mp4");
  videoData.schwarz.English.stage28Time = 13000; // 28000;
  videoData.schwarz.English.stage28Subs = require("../assets/video/beatTheHacker2Phy/English/stage28.vtt");

  videoData.schwarz.English.stage31 = require("../assets/video/schwarz/English/stage31.mp4");
  videoData.schwarz.English.stage31Time = 54000; // 28000;
  videoData.schwarz.English.stage31Subs = require("../assets/video/schwarz/English/stage31.vtt");

  videoData.schwarz.English.deepfake1 = require("../assets/video/beatTheHacker2Phy/English/deepfake1.mp4");
  videoData.schwarz.English.deepfake1Time = 32000; // 28000;
  videoData.schwarz.English.deepfake1Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake1.vtt");

  videoData.schwarz.English.deepfake3 = require("../assets/video/beatTheHacker2Phy/English/deepfake2.mp4");
  videoData.schwarz.English.deepfake3Time = 49000; // 28000;
  videoData.schwarz.English.deepfake3Subs = require("../assets/video/beatTheHacker2Phy/English/deepfake2.vtt");

  videoData.schwarz.English.stage121 = require("../assets/video/beatTheHacker2Phy/English/stage121.mp4");
  videoData.schwarz.English.stage121Time = 10000; // 28000;
  videoData.schwarz.English.stage121Subs = require("../assets/video/beatTheHacker2Phy/English/stage121.vtt");

  return videoData;
}
